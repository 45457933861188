<!-- //拉霸 -->
<div class="activity" *ngIf="ActivityRes.activityDraw.cImageType == 0">
  <div class="date">{{ActivityRes.activity?.cSDate | date: 'yyyy-MM-dd'}} ~ {{ActivityRes.activity?.cEDate | date:
    'yyyy-MM-dd'}}</div>
  <div class="title" (click)="change()">{{ActivityRes.activity?.cName}}</div>
  <div class="sub_title">{{ActivityRes.activity?.cSubTitle}}</div>
  <div class="box">
    <!-- 每日 -->
    <div class="img" *ngIf="isChange">
      <img class="img__gift" src="/assets/images/icashpay-icon/bar_point.png" alt="抽獎點數">
      <img class="img__axis" src="/assets/images/icashpay-icon/handbar.png" alt="抽獎拉霸軸心">
      <img class="img__hand rotate-origin" src="/assets/images/icashpay-icon/hand.png" alt="抽獎手把"
        [ngClass]="{'rotate-animation': isLottery}">
      <img class="img__gift_gif" src="/assets/images/icashpay-icon/comp.gif" alt="抽獎動畫" *ngIf="isLottery">
      <!-- <img class="img__go" src="/assets/images/icashpay-icon/go.png" alt="抽獎按鈕" [ngClass]="{'img__go_disable': isSigninModalOpen}" (click)="Golottery()"> -->
      <div class="img__go"> <button class="butn" [disabled]="isLottery||isSigninModalOpen" (click)="Golottery()">
          <span *ngIf="!isLottery&&!isSigninModalOpen">GO</span>
          <span *ngIf="isLottery||isSigninModalOpen">抽獎中...</span>
        </button></div>
    </div>
  </div>
  <div class="log">
    <div class="last">剩餘機會：<span>{{ActivityRes.memberInfo?.cDrawNumber}}</span> 次</div>
    <div class="text" (click)="goto('activity/log')">活動紀錄 <img src="/assets/images/icashpay-icon/right-arrow.png"
        alt="right-arrow"></div>
  </div>
  <div class="more">
    <div class="text" (click)="GetBuyNumber()" *ngIf="!buy && ActivityRes.activityDraw?.cPointToDraw ?? 0 > 0">
      {{ActivityRes.activityDraw?.cPointToDraw}} 點再抽一次</div>
    <div class="text" *ngIf="buy">購買中...</div>
  </div>

  <div class="rule" *ngIf="!isOpen">
    <div class="rule__title">活動規則</div>
    <div class="rule__content one-line-text" [innerHTML]="ActivityRes.activity?.cRuleContent|html">
    </div>
    <div class="rule__open" (click)="openContent()">展開內容
      <img style="margin-left: 8px;" src="/assets/images/icashpay-icon/arrow-bottom.png" alt="arrow-bottom">
    </div>
  </div>

  <div class="close" (click)="goto('/')">
    <img src="/assets/images/icashpay-icon/close.svg" alt="">
  </div>
</div>
<!-- //紅包 -->
<div class="activity-red" *ngIf="ActivityRes.activityDraw.cImageType == 1">
  <div class="date">{{ActivityRes.activity?.cSDate | date: 'yyyy-MM-dd'}} ~ {{ActivityRes.activity?.cEDate | date:
    'yyyy-MM-dd'}}</div>
  <div class="title">
    <div class="img-money2"><img src="/assets/images/icashpay-icon/money2.png" alt="裝飾錢幣2" [ngClass]="{'moveUp': isLottery}"></div>
    <span> {{ActivityRes.activity?.cName}}</span>
    <div class="img-light"><img src="/assets/images/icashpay-icon/light.png" alt="裝飾光" [ngClass]="{'moveUp': isLottery}"></div>
  </div>
  <div class="sub_title">
    {{ActivityRes.activity?.cSubTitle}}</div>
  <div class="box">
    <!-- 每日 -->
    <div class="img" *ngIf="isChange">
      <img class="img__star1" src="/assets/images/icashpay-icon/star1.png" alt="裝飾星星1"   [ngClass]="{'grow-animationbig': isLottery}">
      <img class="img__star2" src="/assets/images/icashpay-icon/star2.png" alt="裝飾星星2"   [ngClass]="{'grow-animation': isLottery}">
      <img class="img__money1" src="/assets/images/icashpay-icon/money1.png" alt="裝飾錢幣1" [ngClass]="{'move-animations': isLottery}">
      <!-- <img class="img__gift" src="/assets/images/icashpay-icon/star1.png" alt="裝飾星星2"> -->
      <div class="img__go"> <button class="butn" [disabled]="isLottery||isSigninModalOpen" (click)="Golottery()">
          <span *ngIf="!isLottery&&!isSigninModalOpen">好運降臨</span>
          <span *ngIf="isLottery||isSigninModalOpen">抽獎中...</span>
        </button></div>
    </div>
  </div>
  <div class="log">
    <div class="last">剩餘機會：<span>{{ActivityRes.memberInfo?.cDrawNumber}}</span> 次</div>
    <div class="text" (click)="goto('activity/log')">活動紀錄 <img src="/assets/images/icashpay-icon/right-arrow.png"
        alt="right-arrow"></div>
  </div>
  <div class="more">
    <div class="text" (click)="GetBuyNumber()" *ngIf="!buy && ActivityRes.activityDraw?.cPointToDraw ?? 0 > 0">
      {{ActivityRes.activityDraw?.cPointToDraw}} 點再抽一次</div>
    <div class="text" *ngIf="buy">購買中...</div>
  </div>

  <div class="rule" *ngIf="!isOpen">
    <div class="rule__title">活動規則</div>
    <div class="rule__content one-line-text" [innerHTML]="ActivityRes.activity?.cRuleContent|html">
    </div>
    <div class="rule__open" (click)="openContent()">展開內容
      <img style="margin-left: 8px;" src="/assets/images/icashpay-icon/arrow-bottom.png" alt="arrow-bottom">
    </div>
  </div>

  <div class="close" (click)="goto('/')">
    <img src="/assets/images/icashpay-icon/close.svg" alt="">
  </div>
</div>





<app-modal [isModalOpen]="isSigninModalOpen" (modalClose)="isSigninModalOpen=$event">
  <h3 headerContent class="general-modal__title__privacy">
    <img style="height: 56px;" src="/assets/images/icashpay-icon/gift-modal.png" alt=""
      *ngIf="ActivityInfo.result?.cIsWinner">
    <img style="height: 56px;" src="/assets/images/icashpay-icon/Nodraw.png" alt=""
      *ngIf="!ActivityInfo.result?.cIsWinner">
  </h3>
  <div bodyContent class="general-modal__content-wrap" *ngIf="ActivityInfo.result?.cType!=-1">
    <div class="general-modal__content-wrap__title" *ngIf="ActivityInfo.result?.cIsWinner">恭喜您中獎</div>
    <div class="general-modal__content-wrap__title" *ngIf="!ActivityInfo.result?.cIsWinner">未中獎</div>
    <div class="general-modal__content-wrap__content" *ngIf="ActivityInfo.result?.cType==0">恭喜獲得
      <span style="color: #F68B1F;">{{ActivityInfo.result?.cAction}}</span>
    </div>
    <div class="general-modal__content-wrap__content" *ngIf="ActivityInfo.result?.cType == 1">恭喜獲得
      <span style="color: #F68B1F;">{{ActivityInfo.result?.cAction}}</span>
      點數
    </div>
    <div class="general-modal__content-wrap__content" *ngIf="!ActivityInfo.result?.cIsWinner">
      {{ActivityInfo.result?.cAction}}
    </div>
  </div>

  <div bodyContent class="general-modal__content-wrap" *ngIf="ActivityInfo.result?.cType==-1">
    <div class="general-modal__content-wrap__content" *ngIf="!ActivityInfo.result?.cIsWinner">
      {{ActivityInfo.result?.cAction}}
    </div>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="close()">確認</button>
  </div>
</app-modal>

<app-modal
  [isModalOpen]="isSigninModalOpenBuy&& msg !== null && msg !== '' ? true : false||isSigninModalOpenBuy&& firstmsg !== null && firstmsg !== '' ? true : false"
  (modalClose)="isSigninModalOpenBuy=$event">
  <h3 headerContent class="general-modal__title__privacy">
    <img style="width: 97px; height: 56px;" src="/assets/images/icashpay-icon/gift-modal.png" alt=""
      *ngIf="!NoDrawNumber">
    <img style="height: 56px;" src="/assets/images/icashpay-icon/Nodraw.png" alt="" *ngIf="NoDrawNumber">
  </h3>
  <div bodyContent class="general-modal__content-wrap">
    <div class="general-modal__content-wrap__title">系統通知</div>
    <div class="general-modal__content-wrap__content" *ngIf="msg&&buy">
      {{msg}}
    </div>
    <div class="general-modal__content-wrap__content" *ngIf="msg&&NoDrawNumber">
      {{msg}}
    </div>
    <div class="general-modal__content-wrap__content" *ngIf="firstmsg&&!buy">
      {{firstmsg}}
    </div>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--cancel" (click)="close()">確認</button>
  </div>
</app-modal>

<div class="bg" *ngIf="isOpen" (click)="openContent()">
  <div class="rule">
    <div class="rule__title">活動規則</div>
    <div class="rule__content" [innerHTML]="ActivityRes.activity?.cRuleContent|html"></div>
    <div class="rule__open">收合內容
      <img style="margin-left: 8px;transform: rotate(180deg);" src="/assets/images/icashpay-icon/arrow-bottom.png"
        alt="arrow-bottom">
    </div>
  </div>
</div>
